import { Link } from 'gatsby';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import styles from './facebook-posts-list.module.scss';
import { queryFacebookPosts } from '../queries/facebookPosts';
import { FacebookPostNode } from '../templates/facebookPost';

interface FacebookPostListProps {
  limit?: number,
}

const FacebookPostList: React.SFC<FacebookPostListProps> = ({
  limit
}) => {
  const allFacebookPosts: ({ node: FacebookPostNode }[]) = queryFacebookPosts();
  const facebookPosts = allFacebookPosts.filter((_, index) => !limit || index < limit);
  return (
    <div className="d-flex flex-rows flex-wrap">
      {facebookPosts.map(({ node }) => (
        <Link
          key={`fb-post-${node.id}`}
          to={`/posts/${node.fields.slug}`}
          className={`${styles.rowStriped} ${styles.rowHover} col-12 col-md-6 deco-none`}>
          <div className={`d-flex float-left h-100 px-3 px-lg-3 px-xl-3 text-center ${styles.imageContainer}`}>
            <img className={`my-0 shadow ${node.picture ? 'align-self-start overflow-auto' : 'd-none'}`} src={`${node.picture}`} />
          </div>
          <div className="flex-grow-1 px-3 text-left overflow-hidden">
            <ul className="list-inline">
              <li className="list-inline-item">
                <FontAwesomeIcon icon={faCalendar} />
                <span className="ml-2 mr-4">{`${node.created_date}`}</span>
              </li>
            </ul>
            <p className="overflow-hidde mb-0">
              { node.message && node.message.length > 135
                ? `${node.message.substring(0, 120 + node.message.substring(120).indexOf(' '))}...`
                : node.message
              }
            </p>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default FacebookPostList;
