import { useStaticQuery, graphql } from 'gatsby';
import { FacebookPostNode } from '../templates/facebookPost';

export const queryFacebookPosts = (): ({ node: FacebookPostNode }[]) => {
  const { allFacebookPosts } = useStaticQuery(
    graphql`
      query facebookPosts {
        allFacebookPosts(filter: {created_time: {gte: "2019-09-01T00:00:00+0100"}, message: {ne: null}}, sort: {fields: created_time, order: DESC}) {
          edges {
            node {
              fields {
                slug
              }
              id
              message
              picture
              full_picture
              permalink_url
              created_time
              created_date: created_time(formatString: "dd DD MMMM YYYY", locale: "nl-NL")
              smonth: created_time(formatString: "MMM", locale: "nl-NL")
              syear: created_time(formatString: "YYYY", locale: "nl-NL")
            }
          }
        }
      }
    `
  )
  return allFacebookPosts.edges
}