import * as React from 'react';
import Layout from '../components/layout';
import PostList from '../components/facebook-post-list';
import SEO, { SeoModel } from '../components/seo';

interface PostsPageProps {
  location: Location,
}

export default class PostsPage extends React.Component<PostsPageProps> {

  public render() {

    const seo: SeoModel = {
      title: 'Nieuws'
    };

    return (
      <Layout location={this.props.location}>
        <SEO model={seo} />
        <div className="mt-5" />
        <section id="posts" className={`bg-white py-5`}>
          <h1 className="my-5 text-center">Nieuws</h1>
          <div className="container-lg">
            <PostList />
          </div>
        </section>
      </Layout>
    );
  }
}
