import { useStaticQuery, graphql } from 'gatsby';

export interface SiteMetadata {
  siteUrl: string,
  name: string,
  title: string,
  description: string,
  profileImageUrl: string,
  facebookId: string,
  facebook: string
  flickr: string
  instagram: string
  linkedin: string
  twitter: string
  youtube: string
}

export const querySiteMetadata = (): SiteMetadata => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            siteUrl
            name
            title
            description
            profileImageUrl
            facebookId
            facebook
            flickr
            instagram
            linkedin
            twitter
            youtube
          }
        }
      }
    `
  )
  return site.siteMetadata
}