import * as React from 'react';
import { Helmet } from 'react-helmet';
import { querySiteMetadata, SiteMetadata } from '../queries/siteMetadata';

export interface SeoModel {
  title?: string,
  description?: string,
  imageUrl?: string,
  isArticle?: boolean
}

interface SEOProps {
  pathname?: string,
  model: SeoModel
}

const SEO: React.SFC<SEOProps> = ({
  model,
}) => {
  const siteMetaData: SiteMetadata = querySiteMetadata();
  const seo = {
    title: model.title,
    description: model.description || siteMetaData.description,
    type: model.isArticle ? 'article' : 'website',
    image: model.imageUrl || `${siteMetaData.siteUrl}${siteMetaData.profileImageUrl}`,
  }
  const titleSuffix = ` | ${siteMetaData.title}`;
  const pageTitle = seo.title ? `${seo.title}${titleSuffix}` : siteMetaData.title;

// https://stackoverflow.com/questions/54834930/how-to-include-local-javascript-on-a-gatsby-page

  return (
    <Helmet
      defer={false}
      title={seo.title}
      titleTemplate={`%s${titleSuffix}`}
      defaultTitle={siteMetaData.title}
    >
      <html lang="nl" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1.0"
      />

      <meta name="title" content={pageTitle} />
      <meta name="description" content={seo.description} />

      <meta property="og:url" content={siteMetaData.siteUrl} />
      <meta property="og:site_name" content={siteMetaData.name} />
      <meta property="fb:id" content={siteMetaData.facebookId} />
      <meta property="og:locale" content="nl_NL" />
      <meta property="og:locale:alternate" content="en_GB" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:type" content={seo.type} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:image:width" content="360" />
      <meta property="og:image:height" content="360" />

      <script
        dangerouslySetInnerHTML={{ __html:`
          <!-- MailerLite Universal -->
          (function(m,a,i,l,e,r){ m['MailerLiteObject']=e;function f(){
          var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);}
          f.q=f.q||[];m[e]=m[e]||f.bind(f.q);m[e].q=m[e].q||f.q;r=a.createElement(i);
          var _=a.getElementsByTagName(i)[0];r.async=1;r.src=l+'?v'+(~~(new Date().getTime()/1000000));
          _.parentNode.insertBefore(r,_);})(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');

          var ml_account = ml('accounts', '2469604', 'l7u2w2o7m5', 'load');
          </script>
          <!-- End MailerLite Universal -->
        `}}
      />

    </Helmet>
  );
}

export default SEO;
